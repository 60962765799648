import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        padding: 10,
        [theme.breakpoints.up('md')]: {
            width: '650px'
        },
    }
}));

export default function (props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {props.children}
        </div>
    );
};
