import config from './config.json';

// Use dummy function as default
export default function() {
    console.log('You shouldn\'t be here')
}

/////////////////////////////////
// Common Values functionality //
/////////////////////////////////

export function getEndpoint() {
    let endpoint = window.location.host
    if (window.location.hostname === 'localhost' || window.location.hostname.startsWith('192.168.')) {
        console.log("Running in development mode, targeting dev endpoint")
        endpoint = config.endpoint;
    }
    return endpoint
}


