import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Paper } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  }
}));

export default function MUIFormSection(props) {
  const classes = useStyles();

  return (
      <Grid item xs={12}>
          <Paper className={classes.paper}>
            {props.children}
          </Paper>
      </Grid>
  )
};
