import React, { useState, useEffect, Fragment } from 'react'
import {useRouteMatch} from 'react-router-dom'
import Base from '../components/Base'
import MUIForm from '../components/MUIForm'
import MUIFormSection from '../components/MUIFormSection'
import { Button, Grid, TextField } from '@material-ui/core'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import * as util from '../utils'

export default function () {
    const [, setState] = useState();
    const match = useRouteMatch()
    const room = match.params.room
    const [webSocket, setwebSocket] = useState(null);
    const [newOption, setNewOption] = useState('')
    const [options, setOptions] = useState([])
    const [voteText, setVoteText] = useState('Vote')
    const [displayResults, setDisplayResults] = useState(false)
    const [hasVoted, setHasVoted] = useState(false)
    const [vote_results, setVoteResults] = useState({
        "present": 0,
        "voted": 0,
        "tallies": []
    })

    const onWebSocketConnect = (event) => {
        console.log("Connected")
        event.srcElement.send(JSON.stringify({
            "Action": "Get",
            "Type": "Option",
            "Room": room
        }))
    }

    const onWebSocketMessage = (event) => {
        let data = JSON.parse(event.data)
        console.log(data)
        if (data.Type === "Option" && data.Action === "Add") {
            addOption(data)
        }
        else if (data.Type === "Option" && data.Action === "Initialize") {
            data.options.forEach(function(option) {
                addOption({'Name': option.name})
            })
        }
        else if (data.Type === "Results" && data.Action === "Update") {
            setVoteResults(data.Results)
            if (hasVoted) {
                setDisplayResults(true)
            }
        }
        else if (data.Type === "Room" && data.Action === "Reset") {
            window.location.reload(false);
        }
    }

    const addOption = (data) => {
        let tempOptions = options
        tempOptions.push({ 'name': data.Name })
        setOptions(tempOptions)
        setState({})
    }

    const handleSubmitOption = (event) => {
        if (newOption !== '') {
            webSocket.send(JSON.stringify({
                "Action": "Add",
                "Type": "Option",
                "Room": room,
                "Name": newOption
            }))
            setNewOption('')
        }
        console.log(event)
    }

    const submitVote = (event) => {
        setVoteText("Update Vote")
        setHasVoted(true)
        webSocket.send(JSON.stringify({
            "Action": "Upsert",
            "Type": "Vote",
            "Room": room,
            "vote": options
        }))
    }

    const resetRoom = (event) => {
        webSocket.send(JSON.stringify({
            "Action": "Reset",
            "Type": "Room",
            "Room": room
        }))
    }

    const onDragEnd = (event) => {
        if (!event.destination || event.reason === 'CANCEL') {return}
        let tempOptions = options
        tempOptions.splice(event.source.index, 1)
        tempOptions.splice(event.destination.index, 0, {'name': event.draggableId})
        setOptions(tempOptions)
        setState({})
    }

    useEffect(() => {
        let socket = new WebSocket("wss://" + util.getEndpoint() + "/websocket/?room=" + room)
        socket.onopen = (event) => onWebSocketConnect(event)
        socket.onclose = () => { console.log("Disconnected") }
        socket.onmessage = (event) => onWebSocketMessage(event)
        setwebSocket(socket)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Base title="Websocket Test">
            <MUIForm>
                <MUIFormSection>
                    <h2>Add Options to the EVote</h2>
                    <Grid container spacing={3} alignItems="center" justify="center">
                        <Grid item xs={12} md={3}>
                            <TextField
                                label={"New option"}
                                value={newOption}
                                onChange={(e) => {
                                    setNewOption(e.target.value)
                                }}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmitOption(e)
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </MUIFormSection>
                <MUIFormSection>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={false}
                                    onClick={handleSubmitOption}
                                >
                                    Add Option
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </MUIFormSection>
                <MUIFormSection>
                    <h2>Order your options</h2>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="options">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    {provided.placeholder}
                                    {options.map((row, index) => (
                                        <Draggable draggableId={row.name} index={index} key={row.name}>
                                            {(provided) => (
                                                <div key={row.name}
                                                    {...provided.dragHandleProps} 
                                                    {...provided.draggableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <h3 align="center">
                                                        {row.name}
                                                    </h3>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                                
                            )}
                        </Droppable>
                    </DragDropContext>
                </MUIFormSection>
                <MUIFormSection>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={false}
                                    onClick={submitVote}
                                >
                                    {voteText}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </MUIFormSection>
                <MUIFormSection>
                    <div>
                        <Fragment>
                        <div>
                            <h2>Results ({vote_results.voted}/{vote_results.present} reporting)</h2>
                        </div>
                    </Fragment>
                        <div>
                            <ol>
                                {vote_results.tallies.map((row, index) => (
                                    <li key={row.name}>{row.name} ({row.score} points)</li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </MUIFormSection>
                <MUIFormSection>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={false}
                                    onClick={resetRoom}
                                >
                                    Reset Room
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </MUIFormSection>
            </MUIForm>

        </Base>
    )
}
