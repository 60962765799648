import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import Welcome from '../pages/Welcome'
import Room from '../pages/Room';

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact strict component={Welcome} />
                <Route path='/:room' component={Room} />
                <Route render={() => <Redirect to="/" />} />
            </Switch>
        </Router>
    )
}