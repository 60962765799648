import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#8A8FD0',
            dark: '#8A8FD0'
        },
        // secondary: {},
        error: {
            main: '#FE1818' // "Alerts and errors"
        },
        warning: {
            main: '#787CB2'
        },
        info: {
            main: '#2CB5E5' // "Links and highlights" (blue)
        },
        success: {
          main: '#71BE45' // "Light green highlight"
        },
        background: {
            paper: '#8A8FD0',
            default: '#8A8FD0',
        }
    },
    typography: {
        fontFamily: [
            'Open Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif'
        ]
    }
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%'
  },
}));

function PrologisSignInBase(props) {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters={true} maxWidth={false} className={classes.root}>
                <Grid container alignItems="center" justify="center" spacing={3}>
                    {props.children}
                </Grid>
            </Container>
        </ThemeProvider>
    )
}

export default PrologisSignInBase
